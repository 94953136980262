$tertiary: #4183c4;


*, *:hover, *:active, *:focus{
  outline: none;
}

@import '../node_modules/bulmaswatch/flatly/variables';
@import "../node_modules/bulma/bulma";
@import '../node_modules/bulmaswatch/flatly/overrides';

.homepage-hero{
  padding-bottom: 100px;
  min-height: 700px;
  overflow: hidden;

  .hero-body .container{
    padding: 3em 0 1em;

    .button:first-child{
      margin-right: 1em;
    }
  }

  .hero-foot img {
    max-width: 100vw;
    width: 1000px;
    display: block;
    margin: 0 auto;
  }
}

.feature-shoutout {
  font-size: 1.2em;

  svg{
    height: 36px;
    vertical-align: middle;
    padding-right: 5px;
    fill: #333;
  }

  a{
    color: darken($turquoise, 20%) !important;
  }
}

a.author {
  display: inline-block !important;
}

.page .card{
  margin-bottom: 2em;

  background-color: #fff;
  box-shadow: 0 2px 3px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  border: none;
  border-radius: 0.2em;

  .media-content a{
    display: block;
  }
}

.navbar .navbar-brand {
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  input {
    height: 50%;
    padding: 0.3rem;
    margin-right: 0.75rem;
  }
}


// Mobile Styles

.navbar {
  background-color: #f4f4f4;
}

.navbar-background{
  -webkit-transition: opacity ease 300ms;
  transition: opacity ease 300ms;

  opacity: 0;
}

.navbar-menu{
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
}

@media screen and (max-width:1023px){
  .navbar .navbar-brand {
    justify-content: initial;
  }

  .navbar-menu{
    position: fixed;
    bottom: 0;
    top: 0;
    left: -160px;
    z-index: 2;
    opacity: 1;
    display: block;
  }
}

.navbar-menu .logo{
  display: none;
}

.navbar-menu.is-active{
  -webkit-transition: all ease 300ms;
  transition: all ease 300ms;
  left: 0;

  .navbar-item{
    padding: 0.5em 2em 0.5em 1.5em;
    color: #333;
  }

  .logo{
    display: block;
  }
}

a.navbar-item, .navbar-link {
  color: #333;
}

.navbar-burger.burger{
  color: #333;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
}

.navbar-background.is-active{
  background: rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 1;
}

a {
  color: #528321;
}

a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  color: #333;
}

.toc {
  padding-bottom: 10px;
}

.menu {
  font-size: 1rem;
}
.menu.is-small {
  font-size: 0.85em;
}
.menu.is-medium {
  font-size: 1.25rem;
}
.menu.is-large {
  font-size: 1.5rem;
}

.menu-list {
  line-height: 1;
  font-size: 0.9em;
}
.menu-list a {
  border-radius: 3px;
  color: #34495e;
  display: block;
  padding: 0.5em 0.75em;
}
.menu-list a:hover {
  background-color: #ecf0f1;
  color: #2b3c4e;
}
.menu-list a.is-active {
  background-color: #528321;
  color: #fff;
}
.menu-list li ul {
  border-left: 1px solid #dee2e5;
  margin: 0.75em;
  padding-left: 0.75em;
}

.menu-label {
  color: #528321;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.menu-label:not(:first-child) {
  margin-top: 1em;
}
.menu-label:not(:last-child) {
  margin-bottom: 0.5em;
}

code {
  background-color: #ecf0f1;
  color: black;
  font-size: 0.85em;
  font-weight: normal;
  padding: .2em .4em;
  border-radius: 6px;
}

.button.is-success {
  background-color: #528321;
  border-color: transparent;
  color: #fff;
}

.button.is-light {
  background-color: #f4f4f4;
}

.hero.is-primary .navbar {
  background-color: #f4f4f4;
  color: #333;
}

.hero.is-primary {
  background-color: white;
}

.hero.is-primary .title {
  color: #333;
}

.hero.is-primary .subtitle {
  color: #333;
}

.hero.is-link {
  background-color: #f4f4f4;
  color: #333;
}

.hero.is-link .subtitle {
  color: #333;
}

.feature-shoutout a {
  color: #528321 !important;
}

.hero.is-primary a.navbar-item:hover, .hero.is-primary a.navbar-item.is-active, .hero.is-primary .navbar-link:hover, .hero.is-primary .navbar-link.is-active {
  background-color: #e5eaec;
}

.hero-body {
  padding: 2rem 1rem;
}

.homepage-hero .hero-foot img {
  max-width: 100vw;
  width: 1000px;
  border: 1px solid black;
  display: block;
  margin: 0 auto;
}

.button.is-success.is-outlined {
  border-color: #528321;
  color: #528321;
}

img[alt="Hosted Gitea"] {
  height: 100px;
}

a:hover {
  color: #528321;
}

.button.is-success:hover, .button.is-success.is-hovered {
  background-color: #528321;
  border-color: transparent;
  color: #fff;
}

.button.is-success.is-outlined:hover, .button.is-success.is-outlined.is-hovered, .button.is-success.is-outlined:focus, .button.is-success.is-outlined.is-focused {
  background-color: #528321;
  border-color: #528321;
  color: #fff;
}
